.navbar {
    width: 100%;
    height: 55px;
    border-bottom: 1px solid #e6e6e6;
    background-color: #ffffff;
    padding-top: 10px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1;
}

.nav-links-container button:not(#sign-in-button) {
    top: 5px !important;
    color: rgb(72, 72, 72);
    font-weight: 400;
}

#sign-out-button {
    color: rgb(0, 120, 65) !important;
}

.logo {
    font-size: 1.5em;
    /*font-family: "Joe182", sans-serif;*/
    font-family: "S Bonus Display", sans-serif;
    font-weight: bold;
    color: rgb(0, 120, 65);
    float: left;
    padding: 10px;
    text-decoration: none;
}

.profile {
    text-align: right;
    display: flex;
    top: 2px;
    position: relative;
    float: right;
    justify-content: center;
    align-items: center;
}

.profile-link {
    color: inherit;
    text-decoration: inherit;
}
