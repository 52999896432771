#heading-navigator {
    position: fixed;
    bottom: 20px;
    z-index: 999;
    right: 30px;
    display: flex;
    flex-direction: column;
}

#heading-navigator button {
    width: 50px;
    min-width: 50px;
    height: 50px;
    margin: 1px;
}

#heading-navigator img {
    width: 18px;
}
