* {
    margin: 0;
    padding: 0;
}

html,
body {
    font-family: "S Bonus UX", Roboto, Arial, sans-serif;
    background-color: rgb(246, 246, 246);
    height: 100%;
}

.hidden {
    display: none !important;
}

.product-search-input-container {
    display: inline-flex;
    flex-wrap: wrap;
    width: 500px;
}
.product-search-input-container button {
    width: 2.5rem;
}
.product-search-input {
    flex-basis: 80%;
}
.invalid-product-id {
    padding: 3px;
}

.clear-search-input-button,
.clear-search-input-button:active {
    margin-left: -2.5rem;
    background-color: rgb(255, 255, 255, 0.1) !important;
}

.full-product-ids {
    color: rgb(128, 128, 128);
}

.loading-container {
    margin-top: 30px;
}

.loading-container span:first-child {
    animation: animationUpDown 0.9s linear infinite;
}
.loading-container span:nth-child(2) {
    animation: animationUpDown 0.9s linear infinite;
    animation-delay: 0.3s;
}
.loading-container span:last-child {
    animation: animationUpDown 0.9s linear infinite;
    animation-delay: 0.6s;
}

@keyframes animationUpDown {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-21px);
    }
}

#main-content {
    padding: 10px;
    background-color: #fff;
    min-height: 85vh;
}

p {
    margin: 16px 0;
}

table,
tr,
td,
th {
    margin: 18px;
    border: 1px solid black;
    border-collapse: collapse;
    padding: 5px;
}

.table-wrapper {
    overflow: auto;
}

.width-limit {
    max-width: 1440px;
    margin: 0 auto;
}

td ul {
    margin-left: 16px;
}

.search-option-switch {
    margin: 0.3em;
    padding-right: 0.2em;
}

.pre-wrap {
    white-space: pre-wrap;
}

.bonus-buy-table {
    white-space: pre-line;
    text-align: left;
    min-width: 50%;
}

.bonus-buy-table tr th:first-child,
.bonus-buy-table tr td:first-child {
    width: 11em;
    min-width: 11em;
    max-width: 11em;
    word-break: break-word;
}

.previous-searches-container {
    margin-bottom: 18px;
}

.multiple-results-container {
    margin-top: 18px;
}

.bb-explanations {
    margin-left: 20px;
}
